import NotificationService from "../../../core/services/notification.service";
import { TimeService } from "@/core/services/time.service";
import dayjs from "dayjs";
import { INSTANCE_ENUM } from "@/core/constants";

const customParseFormat = require("dayjs/plugin/customParseFormat");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export default {
  name: "CustomTable",
  data() {
    return {
      title: "",
      headers: [],
      data: [],
      normal_data: [],
      queryLoading: false,
      levels: false
    };
  },
  methods: {
    //#region Main methods
    /**
     * This function will load the data and show it in a table
     * @param  {Object} tableData
     * @param  {String} tableData.accountNumber The account number of the account
     * @param  {Object} tableData.filters Filters to use for querying
     * @param  {any[]} tableData.headers Headers for the table
     * @param  {String} tableData.instance Which instance should we query? Can only be SNOW_GROUP or SNOW_BELGIUM
     * @param  {String} tableData.levelName What is level field? For incidents -> priority, ...
     * @param  {any[]} tableData.levels If the overview needs to be splitted by the given levels
     * @param  {Object} tableData.pagination Pagination to use for querying
     * @param  {Object} tableData.queryOptions Query Options to use for querying
     * @param  {DocumentNode} tableData.queryString The query to use to query
     * @param  {String} tableData.reportType Which type of table to generate, can be `report` or `overview`
     * @param  {String} tableData.tableName Name of the table which will be used in the export
     * @param  {String} tableData.title Title of the table
     * @param  {String} tableData.value The value in the query
     */
    async loadData(tableData) {
      // Set global vars
      this.headers = tableData.headers;
      this.title = tableData.title;

      // Start retrieving data
      this.queryLoading = true;
      if (tableData.reportType === "report") this.loadReport(tableData);
      else if (tableData.reportType === "overview")
        this.loadOverview(tableData);
      else if (tableData.reportType === "users") this.loadUsers(tableData);
      else NotificationService.error(`Invalid type ${tableData.reportType}`);
    },
    dataLoaded({ tableName, reportType }) {
      this.queryLoading = false;
      this.$emit("dataLoaded", {
        name: tableName,
        data: this.data,
        type: reportType
      });
    },
    //#endregion

    //#region Query Data
    async queryData(query, variables) {
      return await this.$apollo
        .query({
          query: query,
          variables: variables,
          fetchPolicy: "no-cache"
        })
        .then(result => {
          return result.data;
        });
    },
    //#endregion

    //#region Custom Sort
    customSort(items, sortBy, sortDesc) {
      if (sortBy.length === 0 && sortDesc.length === 0) {
        this.data = this.normal_data;
      }
      for (let index = 0; index < sortBy.length; index++) {
        const sortHeader = sortBy[index];
        const isDescending = sortDesc[index];

        if (sortHeader === "priority") {
          this.data.sort((a, b) => {
            if (isDescending) {
              return b.priority.index - a.priority.index;
            } else {
              return a.priority.index - b.priority.index;
            }
          });
        } else {
          this.data.sort((a, b) => {
            if (!isDescending) {
              return a[sortHeader] < b[sortHeader] ? -1 : 1;
            } else {
              return b[sortHeader] < a[sortHeader] ? -1 : 1;
            }
          });
        }
      }
      return this.data;
    },

    //#endregion

    //#region Load Users
    async loadUsers(tableData) {
      let variables = { filters: tableData.filters };
      let userData = await this.queryData(
        tableData.queryString,
        variables
      ).then(result => result);
      let users =
        userData.reports.service_management_report.customer_system_administrator
          .users;
      users = users ? users : [];
      this.data = users;
      this.normal_data = users;
      this.dataLoaded(tableData);
    },

    //#endregion

    //#region Report
    async loadReport(tableData) {
      // Query Data
      let variables = { filters: tableData.filters };
      let reportData = await this.queryData(
        tableData.queryString,
        variables
      ).then(result => result);

      // Manipulate data
      let cleanReport = this.createCleanReport({
        data:
          reportData.reports.service_management_report.overview[tableData.value]
      });
      this.data = cleanReport;
      this.normal_data = cleanReport;
      this.dataLoaded(tableData);
    },

    createCleanReport({ data }) {
      data.forEach(level => {
        let convertedData = level;
        // Level
        convertedData.level = level.level.replace("level_", "");

        // Average times
        let averageResponseTimeString = TimeService.convertSecondsToString(
          level.average_response_time
        );
        let slaResponseTimeString = TimeService.convertSecondsToString(
          level.sla_response_time
        );
        if (averageResponseTimeString && slaResponseTimeString)
          convertedData.average_response_time = `${averageResponseTimeString} / ${slaResponseTimeString}`;
        else if (!averageResolveTimeString && !slaResponseTimeString)
          convertedData.average_response_time = "n/a";
        else
          convertedData.average_response_time = `${
            averageResponseTimeString ? averageResponseTimeString : "n/a"
          } / ${slaResponseTimeString ? slaResponseTimeString : "n/a"}`;

        let averageResolveTimeString = TimeService.convertSecondsToString(
          level.average_resolve_time
        );
        let slaResolveTimeString = TimeService.convertSecondsToString(
          level.sla_resolve_time
        );
        if (averageResolveTimeString && slaResolveTimeString)
          convertedData.average_resolve_time = `${averageResolveTimeString} / ${slaResolveTimeString}`;
        else if (!averageResolveTimeString && !slaResolveTimeString)
          convertedData.average_resolve_time = "n/a";
        else
          convertedData.average_resolve_time = `${
            averageResolveTimeString ? averageResolveTimeString : "n/a"
          } / ${slaResolveTimeString ? slaResolveTimeString : "n/a"}`;

        // Times ok
        convertedData.response_time_ok = level.response_time_ok
          ? `${Math.floor(Number(level.response_time_ok))} %`
          : "n/a";
        convertedData.resolve_time_ok = level.resolve_time_ok
          ? `${Math.floor(Number(level.resolve_time_ok))} %`
          : "n/a";
      });
      return data;
    },
    //#endregion

    //#region Overview
    createCleanOverview({ data, name }) {
      let overviewList = [];
      if (name === "changes") {
        data.forEach(change => {
          let newChange = change;
          let priority = change.priority;
          newChange.priority = this.updatePriority(priority);
          overviewList.push(newChange);
        });
      } else if (name === "incidents" || name === "incidents_critical") {
        data.forEach(incident => {
          let newIncident = incident;
          let priority = incident.priority;
          newIncident.priority = this.updatePriority(priority);
          overviewList.push(newIncident);
        });
      } else if (name === "cases") {
        data.forEach(_case => {
          let newCase = _case;
          let priority = _case.priority;
          newCase.priority = this.updatePriority(priority);
          overviewList.push(newCase);
        });
      } else if (name === "configuration_items") {
        data.forEach(ci => {
          let newCI = ci;
          newCI.ram = ci.ram ? `${ci.ram} MB` : "";
          if (ci.lcom_tag || ci.name) overviewList.push(newCI);
        });
      }
      return overviewList;
    },

    async loadOverview(tableData) {
      let containsSLA =
        "containsSLA" in tableData ? tableData.containsSLA : false;
      let variables = {
        instance: tableData.instance
          ? tableData.instance
          : INSTANCE_ENUM.SNOW_GROUP,
        pagination: tableData.pagination,
        filters: tableData.filters,
        query_options: tableData.queryOptions
          ? tableData.queryOptions
          : { display_value: false }
      };
      if (!containsSLA) variables.account_number = tableData.accountNumber;
      let overviewData = await this.queryData(
        tableData.queryString,
        variables
      ).then(result => result);
      let data = [];
      if (tableData.instance === INSTANCE_ENUM.SNOW_GROUP) {
        data = overviewData[tableData.value].result;
      } else if (!containsSLA) {
        data = overviewData.account[tableData.value].result;
      } else {
        data =
          overviewData.reports.service_management_report.details[
            tableData.value
          ].result;
      }
      // Add to tableData

      if (containsSLA) {
        data = this.setResponseAndResolveTime(data, tableData.tableName);
      }
      let cleanOverview = [];
      if (tableData.levels) {
        this.levels = true;
        cleanOverview = this.splitOverviewDataByLevel(
          data,
          tableData.levels,
          tableData.levelName
        );
      } else if (
        tableData.tableName.includes("open_") ||
        tableData.tableName === "configuration_items" ||
        tableData.tableName === "service_requests"
      ) {
        cleanOverview = data;
      } else {
        cleanOverview = this.createCleanOverview({
          data: data,
          name: tableData.tableName
        });
      }
      this.data = cleanOverview;
      this.normal_data = cleanOverview;
      this.dataLoaded(tableData);
    },

    setResponseAndResolveTime(overviewData) {
      overviewData.forEach(item => {
        item.resolve_time = this.calculateTimes(item.resolve_time);
        item.response_time = this.calculateTimes(item.response_time);
        item.rating = item.rating ? item.rating : "";
      });
      return overviewData;
    },
    calculateTimes(item) {
      let business_duration = "n/a";
      try {
        let datetimeObject = dayjs.utc(item, "YYYY-MM-DD HH:mm:ss").toDate();
        let datetimeSeconds = datetimeObject / 1000;
        business_duration = TimeService.convertSecondsToString(datetimeSeconds);
      } catch {
        business_duration = "n/a";
      }
      return business_duration;
    },

    splitOverviewDataByLevel(cleanOverview, dataObjectLevels, levelName) {
      try {
        cleanOverview.forEach(itil => {
          dataObjectLevels[itil[levelName]].push(itil);
        });
        let levels = [];
        Object.keys(dataObjectLevels).forEach(level => {
          levels.push({ data: dataObjectLevels[level], level: level });
        });
        return levels;
      } catch {
        console.error(cleanOverview);
      }
    },
    //#endregion

    //#region Helpers
    updatePriority(priority) {
      let prioritySplit = priority.split(" - ");
      return {
        index: prioritySplit[0],
        value: prioritySplit[1]
      };
    }
    //#endregion
  }
};
