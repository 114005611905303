import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import NotificationService from "@/core/services/notification.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { GET_BELGIUM_SERVICENOW_ACCOUNTS } from "@/core/services/graphql/queries/service_management_report/belgium/queries";
import ReportView from "./report/report.vue";
import dayjs from "dayjs";
import { INSTANCE_ENUM } from "@/core/constants.js";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    ReportView
  },
  apollo: {
    accounts: {
      query: GET_BELGIUM_SERVICENOW_ACCOUNTS,
      variables: {
        instance: INSTANCE_ENUM.SNOW_GROUP,
        filters: { customer: { value: true } },
        pagination: { limit: -1 }
      },
      update(data) {
        if (data) return data.servicenow_accounts.result;
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  },
  data() {
    return {
      allowed_options: [
        { display: "Full Report", value: "full_report" },
        { display: "All Tickets", value: "all_tickets" },
        { display: "Configuration Items", value: "configuration_items" },
        { display: "Changes", value: "changes" },
        { display: "Cases", value: "cases" },
        { display: "Incidents", value: "incidents" }
      ],
      selected_customer: {},
      date_from: dayjs().subtract(3, "M").toISOString().substr(0, 10),
      date_to: dayjs().toISOString().substr(0, 10),
      report_type: "",
      showDateFromPicker: false,
      showDateToPicker: false,
      dateDisabled: true
    };
  },
  watch: {
    report_type() {
      if (this.report_type != "") {
        this.$refs.reportView.visible = false;
      }
    }
  },
  computed: {
    formattedDateFrom() {
      return this.date_from ? dayjs(this.date_from).format("DD/MM/YYYY") : "";
    },
    formattedDateTo() {
      return this.date_to ? dayjs(this.date_to).format("DD/MM/YYYY") : "";
    }
  },
  methods: {
    loadData() {
      this.$refs.reportView.loadData({
        selected_customer: this.selected_customer,
        date_from: this.date_from,
        date_to: this.date_to,
        report_type: this.report_type
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Service Management Reports"
      }
    ]);
  }
};
