import ItilGraph from "@/view/content/ItilGraph/ItilGraph.vue";
import CustomTable from "@/view/content/CustomTable/CustomTable.vue";
import dayjs from "dayjs";
import { GET_CHANGES } from "@/core/services/graphql/queries/service_management_report/belgium/queries";
import { INSTANCE_ENUM } from "@/core/constants.js";
import { OPERATOR_ENUM } from "../../../../../../../core/constants";

export default {
  name: "Changes",
  components: {
    ItilGraph,
    CustomTable
  },
  data() {
    return {
      date_from: dayjs().toISOString().substr(0, 10),
      date_to: dayjs().subtract(6, "M").toISOString().substr(0, 10),
      changes: {
        pagination: {
          limit: -1
        },
        filters: {
          sys_created_on: {
            value: [
              dayjs().subtract(6, "M").toISOString().substr(0, 10),
              dayjs().subtract(0, "M").toISOString().substr(0, 10)
            ],
            operator: OPERATOR_ENUM.BETWEEN
          }
        },
        headers: [
          { text: "Number", value: "number" },
          { text: "Created", value: "opened_at" },
          { text: "Priority", value: "priority.value" },
          { text: "Title", value: "short_description" },
          { text: "Type", value: "type" },
          { text: "State", value: "state" }
        ]
      },
      account_number: ""
    };
  },
  methods: {
    fetchData(loadObject) {
      this.date_from = loadObject.date_from;
      this.date_to = loadObject.date_to;
      this.changes.filters.sys_created_on = {
        value: [this.date_from, this.date_to],
        operator: OPERATOR_ENUM.BETWEEN
      };
      this.changes.filters.company = {
        value: loadObject.sys_id
      };
      this.$refs.itilTableChange.loadData({
        accountNumber: loadObject.account_number,
        filters: this.changes.filters,
        headers: this.changes.headers,
        instance: INSTANCE_ENUM.SNOW_GROUP,
        pagination: this.changes.pagination,
        queryOptions: { display_value: true },
        queryString: GET_CHANGES,
        reportType: "overview",
        tableName: "changes",
        title: "Changes Last 6 Months",
        value: "changes"
      });
      this.$refs.itilGraphChange.fetchData({
        account_number: loadObject.account_number,
        sys_id: loadObject.sys_id,
        itil_type: "changes",
        date_to: this.date_to,
        date_from: this.date_from
      });
    }
  }
};
