/* eslint-disable no-unused-vars */
import {
  GET_CASES,
  GET_CHANGES,
  GET_CONFIGURATION_ITEMS,
  GET_CRITICAL_INCIDENTS,
  GET_INCIDENTS_LAST_HALF_YEAR
} from "@/core/services/graphql/queries/service_management_report/belgium/queries";
import ItilGraph from "@/view/content/ItilGraph/ItilGraph.vue";
import CustomTable from "@/view/content/CustomTable/CustomTable.vue";
import AllTicketsGraph from "@/view/content/AllTicketsGraph/AllTicketsGraph.vue";
import DocxGenerator from "@/core/services/docxgenerator.service";
import dayjs from "dayjs";
import { INSTANCE_ENUM } from "@/core/constants.js";
import { OPERATOR_ENUM } from "@/core/constants";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "FullReport",
  components: {
    ItilGraph,
    CustomTable,
    AllTicketsGraph
  },
  data() {
    return {
      date_from: dayjs().toISOString().substr(0, 10),
      date_to: dayjs().subtract(6, "M").toISOString().substr(0, 10),
      dataList: {},
      chartDataList: {
        all_tickets: {},
        cases: {},
        incidents: {},
        changes: {}
      },
      configuration_items: {
        data: [],
        pagination: {
          limit: -1
        },
        headers: [
          { text: "Host Name", value: "u_hostname" },
          { text: "Asset Name", value: "u_lcomtag" },
          { text: "Name", value: "name" },
          { text: "Reference", value: "u_reference" },
          { text: "OS Version", value: "os_version" },
          { text: "OS", value: "os" },
          { text: "VPU Cores", value: "u_vpu_cores" },
          { text: "RAM", value: "ram" },
          { text: "SLA", value: "u_sla" }
        ]
      },
      cases: {
        data: [],
        pagination: {
          limit: -1
        },
        filters: {
          opened_at: {
            value: [
              dayjs().subtract(6, "M").toISOString().substr(0, 10),
              dayjs().subtract(0, "M").toISOString().substr(0, 10)
            ],
            operator: OPERATOR_ENUM.BETWEEN
          }
        },
        headers: [
          { text: "Number", value: "number" },
          { text: "Opened", value: "opened_at" },
          { text: "Priority", value: "priority.value" },
          { text: "Title", value: "short_description" },
          { text: "Updated", value: "sys_updated_on" },
          { text: "State", value: "state" }
        ]
      },
      changes: {
        data: [],
        pagination: {
          limit: -1
        },
        filters: {
          opened_at: {
            value: [
              dayjs().subtract(6, "M").toISOString().substr(0, 10),
              dayjs().subtract(0, "M").toISOString().substr(0, 10)
            ],
            operator: OPERATOR_ENUM.BETWEEN
          }
        },
        headers: [
          { text: "Number", value: "number" },
          { text: "Created", value: "opened_at" },
          { text: "Priority", value: "priority.value" },
          { text: "Title", value: "short_description" },
          { text: "Type", value: "type" },
          { text: "State", value: "state" }
        ]
      },
      incidents: {
        criticals: {
          data: [],
          pagination: {
            limit: -1
          },
          filters: {
            priority: {
              value: "1"
            },
            opened_at: {
              value: [
                dayjs().subtract(6, "M").toISOString().substr(0, 10),
                dayjs().subtract(0, "M").toISOString().substr(0, 10)
              ],
              operator: OPERATOR_ENUM.BETWEEN
            }
          }
        },
        last_half_year: {
          data: [],
          pagination: {
            limit: -1
          },
          filters: {
            opened_at: {
              value: [
                dayjs().subtract(6, "M").toISOString().substr(0, 10),
                dayjs().subtract(0, "M").toISOString().substr(0, 10)
              ],
              operator: OPERATOR_ENUM.BETWEEN
            }
          }
        },
        headers: [
          { text: "Number", value: "number" },
          { text: "Opened", value: "opened_at" },
          { text: "Priority", value: "priority.value" },
          { text: "Title", value: "short_description" },
          { text: "Updated", value: "sys_updated_on" },
          { text: "State", value: "state" },
          { text: "Configuration Item", value: "cmdb_ci" }
        ]
      },
      account_number: ""
    };
  },
  methods: {
    fetchData(loadObject) {
      // Define variable
      this.date_from = loadObject.date_from;
      this.date_to = loadObject.date_to;
      this.account_number = loadObject.account_number;
      const tableRefs = [
        {
          ref: "ciTable",
          name: "configuration_items",
          title: "Custom Environment",
          query_string: GET_CONFIGURATION_ITEMS,
          value: "configuration_items"
        },
        {
          ref: "itilTableCase",
          name: "cases",
          title: "Cases Last 6 Months",
          query_string: GET_CASES,
          value: "cases"
        },
        {
          ref: "itilTableChange",
          name: "changes",
          title: "Changes Last 6 Months",
          query_string: GET_CHANGES,
          value: "changes"
        },
        {
          ref: "itilTableIncidentCritical",
          name: "critical_incidents",
          title: "Criticals Incidents Last 6 Months",
          query_string: GET_CRITICAL_INCIDENTS,
          value: "incidents"
        },
        {
          ref: "itilTableIncidentLast",
          name: "incidents",
          title: "Incidents Last 6 Months",
          query_string: GET_INCIDENTS_LAST_HALF_YEAR,
          value: "incidents"
        }
      ];

      // Fetch Table Data
      tableRefs.forEach(table => {
        const dataObject = {
          accountNumber: loadObject.account_number,
          filters: {},
          headers: this[table.value].headers,
          tableName: table.name,
          queryOptions: { display_value: true },
          queryString: table.query_string,
          pagination: {
            limit: -1
          },
          title: table.title,
          value: table.value,
          reportType: "overview",
          instance: INSTANCE_ENUM.SNOW_GROUP
        };
        switch (table.name) {
          case "critical_incidents":
            this[table.value].criticals.filters.opened_at = {
              value: [this.date_from, this.date_to],
              operator: OPERATOR_ENUM.BETWEEN
            };
            dataObject.filters = this.incidents.criticals.filters;
            dataObject.filters.company = { value: loadObject.sys_id };
            dataObject.headers = this.incidents.headers;
            dataObject.pagination = this.incidents.criticals.pagination;
            break;
          case "incidents":
            this[table.value].last_half_year.filters.opened_at = {
              value: [this.date_from, this.date_to],
              operator: OPERATOR_ENUM.BETWEEN
            };
            dataObject.filters = this.incidents.last_half_year.filters;
            dataObject.filters.company = { value: loadObject.sys_id };
            dataObject.headers = this.incidents.headers;
            dataObject.pagination = this.incidents.last_half_year.pagination;
            break;
          case "configuration_items":
            dataObject.filters.company = { value: loadObject.sys_id };
            break;
          default:
            this[table.value].filters.opened_at = {
              value: [this.date_from, this.date_to],
              operator: OPERATOR_ENUM.BETWEEN
            };
            dataObject.filters = this[table.value].filters;
            dataObject.filters.company = { value: loadObject.sys_id };
            dataObject.headers = this[table.value].headers;
            dataObject.pagination = this[table.value].pagination;
        }
        this.$refs[table.ref].loadData(dataObject);
      });

      // Fetch Graphs
      const graphRefs = [
        { ref: "itilGraphIncident", name: "incidents" },
        { ref: "itilGraphChange", name: "changes" },
        { ref: "itilGraphCase", name: "cases" }
      ];
      graphRefs.forEach(graph => {
        this.$refs[graph.ref].fetchData({
          account_number: loadObject.account_number,
          sys_id: loadObject.sys_id,
          itil_type: graph.name,
          date_from: loadObject.date_from,
          date_to: loadObject.date_to
        });
      });
      this.$refs.allTicketGraph.fetchData({
        account_number: loadObject.account_number,
        sys_id: loadObject.sys_id
      });
    },
    dayjs() {
      return dayjs();
    },
    upperCaseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setData(event) {
      this.dataList[`${event.name}_${event.type}`] = event.data;
    },
    createDocx() {
      const generate = {};
      generate.title = `Full_Report_${this.account_number}`;
      generate.templateUrl = "/templates/Full_Report_template.docx";
      generate.data = {
        information: {
          date_from: this.date_from,
          date_to: this.date_to,
          company_name: localStorage.getItem("company_name"),
          customer_number: this.account_number,
          creation_date: dayjs().format("DD/MM/YYYY")
        },
        data: this.dataList
      };
      DocxGenerator.generate(generate);
    }
  }
};
