import VueApexCharts from "vue-apexcharts";
import dayjs from "dayjs";
import NotificationService from "@/core/services/notification.service";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { GET_ALL_TICKETS } from "@/core/services/graphql/queries/service_management_report/belgium/queries";
import { INSTANCE_ENUM } from "@/core/constants.js";
import { OPERATOR_ENUM } from "@/core/constants";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        colors: ["#FFB423", "#4C8C73", "#E15000", "#F3F2EF"],
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          categories: [
            `${dayjs().subtract(3, "M").format("MMM YYYY")}`,
            `${dayjs().subtract(2, "M").format("MMM YYYY")}`,
            `${dayjs().subtract(1, "M").format("MMM YYYY")}`,
            `${dayjs().format("MMM YYYY")}`
          ]
        },
        legend: {
          position: "right"
        },
        fill: {
          opacity: 1
        }
      },
      accounts: {
        changes: [],
        cases: [],
        incidents: [],
        total_count: ""
      },
      graphLoading: true
    };
  },
  methods: {
    fetchData(dataObject) {
      this.graphLoading = true;
      this.account_number = dataObject.account_number;
      this.sys_id = dataObject.sys_id;
      if (this.account_number) {
        this.skipQuery = false;
        this.$apollo.queries.account.start();
      }
    },
    drawChart() {
      this.graphLoading = true;
      this.series = [
        {
          name: "Change",
          data: this.getNameOutList("changes")
        },
        {
          name: "Cases",
          data: this.getNameOutList("cases")
        },
        {
          name: "Incidents",
          data: this.getNameOutList("incidents")
        }
      ];
      this.graphLoading = false;
      setTimeout(() => {
        this.emitEvents();
      }, 10000);
    },
    getNameOutList(name) {
      let countList = [];
      let tempList = [];
      this.chartOptions.xaxis.categories.forEach(category => {
        this.accounts[name].forEach(itil => {
          if (this.checkDate(itil.opened_at, category)) tempList.push(itil);
        });
        countList.push(tempList.length);
      });
      return countList;
    },
    checkDate(inputDate, validateDate) {
      let inputDateISO = dayjs(inputDate, "DD-MM-YYYY").format().slice(0, 10);
      let INPUT_DATE = dayjs(inputDateISO);
      let VALIDATE_DATE = dayjs(validateDate);
      return (
        INPUT_DATE.year() === VALIDATE_DATE.year() &&
        INPUT_DATE.month() === VALIDATE_DATE.month()
      );
    },
    emitEvents() {
      this.$refs.allTicketsChart.chart.dataURI().then(uri => {
        this.$emit("chartsLoaded", {
          data: uri.imgURI,
          name: "all_tickets",
          title: "All Tickets Chart",
          type: "chart"
        });
      });
    }
  },
  apollo: {
    account: {
      query() {
        return GET_ALL_TICKETS;
      },
      variables() {
        return {
          instance: INSTANCE_ENUM.SNOW_GROUP,
          pagination: {
            limit: -1
          },
          change_filters: {
            opened_at: {
              value: dayjs().subtract(3, "M").toISOString().substr(0, 10),
              operator: OPERATOR_ENUM.GREATER_THAN
            },
            company: { value: this.sys_id }
          },
          case_filters: {
            opened_at: {
              value: dayjs().subtract(3, "M").toISOString().substr(0, 10),
              operator: OPERATOR_ENUM.GREATER_THAN
            },
            company: { value: this.sys_id }
          },
          incident_filters: {
            opened_at: {
              value: dayjs().subtract(3, "M").toISOString().substr(0, 10),
              operator: OPERATOR_ENUM.GREATER_THAN
            },
            company: { value: this.sys_id }
          }
        };
      },
      skip() {
        return this.skipQuery;
      },
      update(data) {
        if (data) {
          this.accounts.changes = data.changes.result;
          this.accounts.cases = data.cases.result;
          this.accounts.incidents = data.incidents.result;
          this.$apollo.queries.account.skip = true;
          this.drawChart();
          return data.account;
        }
      },
      // Additional options here
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  }
};
