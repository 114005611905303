import gql from "graphql-tag";

const GET_BELGIUM_SERVICENOW_ACCOUNTS = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ServiceNowAccountFilterInput
  ) {
    servicenow_accounts(
      instance: $instance
      pagination: $pagination
      filters: $filters
    ) {
      total_count
      result {
        number
        sys_id
        name
      }
    }
  }
`;

const GET_CASES = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ServiceNowCaseFilterInput
  ) {
    cases(instance: $instance, pagination: $pagination, filters: $filters) {
      total_count
      result {
        number
        opened_at
        priority
        short_description
        sys_updated_on
        state
      }
    }
  }
`;

const GET_CASES_STATE_PRIORITY = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ServiceNowCaseFilterInput
  ) {
    cases(instance: $instance, pagination: $pagination, filters: $filters) {
      total_count
      result {
        sys_created_on
        state
        priority
      }
    }
  }
`;

const GET_CRITICAL_INCIDENTS = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ItilFilterInput
  ) {
    incidents(instance: $instance, pagination: $pagination, filters: $filters) {
      total_count
      result {
        number
        opened_at
        priority
        short_description
        sys_updated_on
        state
        cmdb_ci
      }
    }
  }
`;

const GET_INCIDENTS_LAST_HALF_YEAR = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ItilFilterInput
  ) {
    incidents(instance: $instance, pagination: $pagination, filters: $filters) {
      total_count
      result {
        number
        opened_at
        priority
        short_description
        sys_updated_on
        state
        cmdb_ci
      }
    }
  }
`;

const GET_INCIDENTS_STATE_PRIORITY = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ItilFilterInput
  ) {
    incidents(instance: $instance, pagination: $pagination, filters: $filters) {
      total_count
      result {
        sys_created_on
        state
        priority
      }
    }
  }
`;

const GET_CHANGES = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ServiceNowChangeFilterInput
  ) {
    changes(instance: $instance, pagination: $pagination, filters: $filters) {
      total_count
      result {
        number
        opened_at
        priority
        short_description
        type
        state
      }
    }
  }
`;

const GET_CHANGES_STATE_PRIORITY = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ServiceNowChangeFilterInput
  ) {
    changes(instance: $instance, pagination: $pagination, filters: $filters) {
      total_count
      result {
        sys_created_on
        state
        priority
      }
    }
  }
`;

const GET_ALL_TICKETS = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $change_filters: ServiceNowChangeFilterInput
    $case_filters: ServiceNowCaseFilterInput
    $incident_filters: ItilFilterInput
  ) {
    changes(
      instance: $instance
      pagination: $pagination
      filters: $change_filters
    ) {
      total_count
      result {
        opened_at
      }
    }
    cases(
      instance: $instance
      pagination: $pagination
      filters: $case_filters
    ) {
      total_count
      result {
        opened_at
      }
    }
    incidents(
      instance: $instance
      pagination: $pagination
      filters: $incident_filters
    ) {
      total_count
      result {
        opened_at
      }
    }
  }
`;

const GET_CONFIGURATION_ITEMS = gql`
  query(
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $filters: ConfigurationItemFilterInput
  ) {
    configuration_items(
      instance: $instance
      pagination: $pagination
      filters: $filters
    ) {
      total_count
      result {
        u_lcomtag
        u_hostname
        name
        os_version
        os
        u_vpu_cores
        ram
        u_sla
        sys_id
        u_reference
      }
    }
  }
`;

export {
  GET_BELGIUM_SERVICENOW_ACCOUNTS,
  GET_CASES,
  GET_CASES_STATE_PRIORITY,
  GET_CRITICAL_INCIDENTS,
  GET_INCIDENTS_LAST_HALF_YEAR,
  GET_INCIDENTS_STATE_PRIORITY,
  GET_CHANGES,
  GET_CHANGES_STATE_PRIORITY,
  GET_ALL_TICKETS,
  GET_CONFIGURATION_ITEMS
};
