import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import AllTickets from "./all_tickets/AllTickets.vue";
import Cases from "./cases/Cases.vue";
import Changes from "./changes/Changes.vue";
import ConfigurationItems from "./configuration_items/ConfigurationItems.vue";
import FullReport from "./full_report/FullReport.vue";
import Incidents from "./incidents/Incidents.vue";

export default {
  props: {
    report_type: {
      type: String
    }
  },
  name: "ReportView",
  components: {
    AllTickets,
    FullReport,
    ConfigurationItems,
    Changes,
    Cases,
    Incidents
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    loadData({ selected_customer, date_from, date_to, report_type }) {
      this.visible = true;

      switch (report_type) {
        case "incidents":
          this.setBreadCrumb("Incidents");
          this.$refs.incidentsView.fetchData({
            account_number: selected_customer.number,
            sys_id: selected_customer.sys_id,
            date_from: date_from,
            date_to: date_to
          });
          break;
        case "cases":
          this.setBreadCrumb("Cases");
          this.$refs.casesView.fetchData({
            account_number: selected_customer.number,
            sys_id: selected_customer.sys_id,
            date_from: date_from,
            date_to: date_to
          });
          break;
        case "changes":
          this.setBreadCrumb("Changes");
          this.$refs.changesView.fetchData({
            account_number: selected_customer.number,
            sys_id: selected_customer.sys_id,
            date_from: date_from,
            date_to: date_to
          });
          break;
        case "configuration_items":
          this.setBreadCrumb("Custom Environment");

          this.$refs.configurationItemsView.fetchData({
            account_number: selected_customer.number,
            sys_id: selected_customer.sys_id,
            date_from: date_from,
            date_to: date_to
          });
          break;
        case "full_report":
          this.setBreadCrumb("Full Report");
          this.$refs.fullReportView.fetchData({
            account_number: selected_customer.number,
            sys_id: selected_customer.sys_id,
            date_from: date_from,
            date_to: date_to
          });
          break;
        case "all_tickets":
          this.setBreadCrumb("All Tickets");
          this.$refs.allTicketsView.fetchData({
            account_number: selected_customer.number,
            sys_id: selected_customer.sys_id,
            date_from: date_from,
            date_to: date_to
          });
          break;
      }
    },
    setBreadCrumb(title) {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: "Service Management Reports"
        },
        {
          title: title
        }
      ]);
    }
  }
};
