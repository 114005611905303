import AllTicketsGraph from "@/view/content/AllTicketsGraph/AllTicketsGraph.vue";
import dayjs from "dayjs";
import { OPERATOR_ENUM } from "@/core/constants";

export default {
  components: {
    AllTicketsGraph
  },
  data() {
    return {
      account_number: "",
      pagination: {
        limit: -1
      },
      filters: {
        opened_at: {
          value: dayjs().subtract(3, "M").toISOString().substr(0, 10),
          operator: OPERATOR_ENUM.GREATER_THAN
        }
      }
    };
  },
  methods: {
    fetchData(loadObject) {
      this.$refs.allTicketGraph.fetchData({
        account_number: loadObject.account_number,
        sys_id: loadObject.sys_id
      });
    }
  }
};
