import { GET_CONFIGURATION_ITEMS } from "@/core/services/graphql/queries/service_management_report/belgium/queries";
import CustomTable from "@/view/content/CustomTable/CustomTable.vue";
import { INSTANCE_ENUM } from "@/core/constants.js";

export default {
  name: "ConfigurationItems",
  components: {
    CustomTable
  },
  data() {
    return {
      configuration_items: {
        pagination: {
          limit: -1
        },
        filters: {},
        headers: [
          { text: "Host Name", value: "u_hostname" },
          { text: "Asset Tag", value: "u_lcomtag" },
          { text: "Name", value: "name" },
          { text: "Reference", value: "u_reference" },
          { text: "OS Version", value: "os_version" },
          { text: "OS", value: "os" },
          { text: "VPU Cores", value: "u_vpu_cores" },
          { text: "RAM", value: "ram" },
          { text: "SLA", value: "u_sla" }
        ]
      },
      account_number: ""
    };
  },
  methods: {
    fetchData(loadObject) {
      this.configuration_items.filters.u_customer = {
        value: loadObject.sys_id
      };
      this.$refs.ciTable.loadData({
        accountNumber: loadObject.account_number,
        filters: this.configuration_items.filters,
        headers: this.configuration_items.headers,
        instance: INSTANCE_ENUM.SNOW_GROUP,
        pagination: this.configuration_items.pagination,
        queryOptions: { display_value: true },
        queryString: GET_CONFIGURATION_ITEMS,
        reportType: "overview",
        tableName: "configuration_items",
        title: "Custom Environment",
        value: "configuration_items"
      });
    }
  }
};
