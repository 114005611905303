import VueApexCharts from "vue-apexcharts";
import DocxGenerator from "@/core/services/docxgenerator.service";
import dayjs from "dayjs";
import NotificationService from "@/core/services/notification.service";
import {
  GET_CASES_STATE_PRIORITY,
  GET_CHANGES_STATE_PRIORITY,
  GET_INCIDENTS_STATE_PRIORITY
} from "@/core/services/graphql/queries/service_management_report/belgium/queries";
import { INSTANCE_ENUM } from "@/core/constants.js";
import { OPERATOR_ENUM } from "@/core/constants";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  components: {
    apexchart: VueApexCharts
  },
  filters: {
    upperCaseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  data() {
    return {
      account_number: "String",
      itil_type: "String",
      date_from: dayjs().toISOString().substr(0, 10),
      date_to: dayjs().subtract(6, "M").toISOString().substr(0, 10),
      stateChart: {
        skipQuery: true,
        series: [],
        chartOptions: {
          colors: ["#FFB423", "#4C8C73", "#E15000", "#363537", "#F3F2EF"],
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }
          ],
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            categories: []
          },
          legend: {
            position: "right"
          },
          fill: {
            opacity: 1
          }
        }
      },
      priorityChart: {
        series: [],
        chartOptions: {
          colors: ["#FFB423", "#4C8C73", "#E15000", "#363537"],
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }
          ],
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            categories: []
          },
          legend: {
            position: "right"
          }
        }
      },
      accounts: {
        changes: [],
        cases: [],
        incidents: [],
        total_count: ""
      },
      graphLoading: false
    };
  },
  methods: {
    fetchData(dataObject) {
      this.graphLoading = true;
      this.account_number = dataObject.account_number;
      this.itil_type = dataObject.itil_type;
      this.date_from = dataObject.date_from;
      this.date_to = dataObject.date_to;
      this.company = dataObject.sys_id;
      if (this.account_number) {
        this.skipQuery = false;
        this.$apollo.queries.account.start();
      }
    },
    upperCaseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async chartDataLoaded() {
      this.updateCategories();
      this.drawPriorityChart();
      this.drawStateChart();

      this.graphLoading = false;
      setTimeout(() => {
        this.emitEvents();
      }, 5000);
    },
    updateCategories() {
      const charts = ["priorityChart", "stateChart"];
      let newMonths = [];
      let months = dayjs(this.date_to).diff(dayjs(this.date_from), "month");
      for (let index = 0; index <= months; index++) {
        newMonths.push(dayjs().subtract(index, "month").format("MMM YYYY"));
      }
      newMonths.reverse();
      charts.forEach(chart => {
        this[chart].chartOptions.xaxis.categories = [];
        this[chart].chartOptions.xaxis.categories.push(...newMonths);
        this.$refs[chart].updateOptions(this[chart].chartOptions);
      });
    },
    async emitEvents() {
      let stateChart = await this.$refs.stateChart.chart.dataURI().then(uri => {
        return uri;
      });
      let priorityChart = await this.$refs.priorityChart.chart
        .dataURI()
        .then(uri => {
          return uri;
        });
      this.$emit("chartsLoaded", {
        data: priorityChart.imgURI,
        name: this.itil_type,
        title: `${this.upperCaseFirstLetter(this.itil_type)} Priority Chart`,
        type: "chart_priority"
      });

      this.$emit("chartsLoaded", {
        data: stateChart.imgURI,
        name: this.itil_type,
        title: `${this.upperCaseFirstLetter(this.itil_type)} State Chart`,
        type: "chart_state"
      });
    },
    drawStateChart() {
      this.$refs.stateChart.chart.updateSeries([
        {
          name: "New",
          data: this.getNameOutList("New", "stateChart", "state")
        },
        {
          name: "Scheduled",
          data: this.getNameOutList("Scheduled", "stateChart", "state")
        },
        {
          name: "Implement",
          data: this.getNameOutList("Implement", "stateChart", "state")
        },
        {
          name: "Closed",
          data: this.getNameOutList("Closed", "stateChart", "state")
        },
        {
          name: "Canceled",
          data: this.getNameOutList("Canceled", "stateChart", "state")
        }
      ]);
    },
    drawPriorityChart() {
      this.$refs.priorityChart.chart.updateSeries([
        {
          name: "1 - Critical",
          data: this.getNameOutList("1 - Critical", "priorityChart", "priority")
        },
        {
          name: "2 - High",
          data: this.getNameOutList("2 - High", "priorityChart", "priority")
        },
        {
          name: "3 - Moderate",
          data: this.getNameOutList("3 - Moderate", "priorityChart", "priority")
        },
        {
          name: "4 - Low",
          data: this.getNameOutList("4 - Low", "priorityChart", "priority")
        }
      ]);
    },
    getNameOutList(name, chartName, filter) {
      let countList = [];
      let tempList = [];
      this[chartName].chartOptions.xaxis.categories.forEach(category => {
        this[this.itil_type].forEach(itil => {
          if (itil[filter] === name && this.checkDate(itil.opened_at, category))
            tempList.push(itil);
        });
        countList.push(tempList.length);
      });
      return countList;
    },
    checkDate(inputDate, validateDate) {
      let inputDateISO = dayjs(inputDate, "DD-MM-YYYY HH:mm:ss").toISOString();
      let INPUT_DATE = dayjs(inputDateISO);
      let VALIDATE_DATE = dayjs(validateDate);
      return (
        INPUT_DATE.year() === VALIDATE_DATE.year() &&
        INPUT_DATE.month() === VALIDATE_DATE.month()
      );
    },
    async createDocx() {
      const generate = {};
      generate.title = `${this.itil_type} State Graph`;
      generate.templateUrl = "/templates/Changes_template_graph.docx";
      generate.data = {};
      generate.data.imgUri = await this.$refs.stateChart.chart
        .dataURI()
        .then(uri => {
          return uri.imgURI;
        });
      DocxGenerator.generate(generate);
    }
  },
  apollo: {
    account: {
      query() {
        if (this.itil_type === "changes") {
          return GET_CHANGES_STATE_PRIORITY;
        } else if (this.itil_type === "cases") {
          return GET_CASES_STATE_PRIORITY;
        } else if (this.itil_type === "incidents") {
          return GET_INCIDENTS_STATE_PRIORITY;
        }
      },
      variables() {
        // Use vue reactive properties here
        return {
          instance: INSTANCE_ENUM.SNOW_GROUP,
          pagination: {
            limit: -1
          },
          filters: {
            opened_at: {
              value: [this.date_from, this.date_to],
              operator: OPERATOR_ENUM.BETWEEN
            },
            company: { value: this.company }
          }
        };
      },
      skip() {
        return this.skipQuery;
      },
      update(data) {
        if (data && this.itil_type in data) {
          this[this.itil_type] = data[this.itil_type].result;
          this.total_count = data[this.itil_type].total_count;
          this.chartDataLoaded();
          this.skipQuery = true;
          return data;
        } else {
          this.graphLoading = false;
        }
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      error(error) {
        NotificationService.error(error);
      }
    }
  }
};
